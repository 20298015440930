<template>
  <div class="px-3">
    <vs-table
      v-if="questions.length"
      :data="questions"
      search
    >
      <template slot="header">
        <div class="d-flex justify-content-between align-items-center w-100 my-3">
          <h2 class="text-dark ml-4">
            Questões Globais
          </h2>
          <vs-button
            color="#66ff66"
            text-color="black"
            class="mr-3"
            icon="add"
            @click="createNewQuestions()"
          >
            Adicionar Questão
          </vs-button>
        </div>
      </template>
      <template slot="thead">
        <vs-th class="text-center">
          <div class="text-center">
            ID
          </div>
        </vs-th>
        <vs-th>Titulo</vs-th>
        <vs-th>Texto</vs-th>
        <vs-th>Tipo</vs-th>
        <vs-th>Categoria</vs-th>
        <vs-th>Options</vs-th>
        <vs-th>listType</vs-th>
        <vs-th>listName</vs-th>
        <vs-th>
          <div class="w-100 text-center">
            Ações
          </div>
        </vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          v-for="(question, i) in data"
          :key="i"
          class="border-top"
          :data="question"
        >
          <vs-td class="py-1">
            {{ question.questionId }}
          </vs-td>
          <vs-td class="py-1">
            {{ question.title }}
          </vs-td>
          <vs-td class="py-1">
            {{ question.text }}
          </vs-td>
          <vs-td class="py-1">
            {{ question.type }}
          </vs-td>
          <vs-td class="py-1">
            {{ question.category }}
          </vs-td>
          <vs-td class="py-1">
            {{ question.options }}
          </vs-td>
          <vs-td class="py-1">
            {{ question.listType }}
          </vs-td>
          <vs-td class="py-1">
            {{ question.listName }}
          </vs-td>
          <vs-td class="py-1">
            <div class="w-100 d-flex justify-content-center">
              <vs-button
                small
                icon="edit"
                @click="editQuestions(question)"
              />
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <vs-popup
      :title="editingQuestions ? 'Alterar Questão' : 'Adicionar nova Questão'"
      :active.sync="questionsDialog"
    >
      <vs-row>
        <vs-col vs-lg="2">
          <label class="small font-weight-bold mt-2">ID:</label>
          <vs-input
            v-if="editingQuestions"
            placeholder="ID"
            class="w-100"
            disabled
            required
          />
          <vs-input
            v-else
            v-model.number="obj.questionId"
            placeholder="ID"
            class="w-100"
            type="number"
          />
        </vs-col>

        <vs-col vs-lg="8">
          <label class="small font-weight-bold mt-2">Titulo:</label>
          <vs-input
            v-model="obj.title"
            placeholder="Titulo"
            class="w-100"
            required
          />
        </vs-col>

        <vs-col vs-lg="2">
          <label class="small font-weight-bold mt-2">Tipo:</label>
          <vs-input
            v-model.number="obj.type"
            placeholder="Tipo"
            class="w-100"
            type="number"
          />
        </vs-col>

        <vs-col vs-lg="12">
          <label class="small font-weight-bold mt-2">Texto:</label>
          <vs-input
            v-model="obj.text"
            placeholder="Texto"
            class="w-100"
            required
          />
        </vs-col>
      </vs-row>

      <vs-row>
        <vs-col vs-lg="3">
          <label class="small font-weight-bold mt-2">Categoria:</label>
          <vs-input
            v-model.number="obj.category"
            placeholder="Categoria"
            class="w-100"
            type="number"
          />
        </vs-col>

        <vs-col vs-lg="3">
          <label class="small font-weight-bold mt-2">Options:</label>
          <vs-input
            v-model="obj.options"
            placeholder="Options"
            class="w-100"
            required
          />
        </vs-col>

        <vs-col vs-lg="3">
          <label class="small font-weight-bold mt-2">ListType:</label>
          <vs-input
            v-model="obj.listType"
            placeholder="ListType"
            class="w-100"
            type="number"
          />
        </vs-col>

        <vs-col vs-lg="3">
          <label class="small font-weight-bold mt-2">ListName:</label>
          <vs-input
            v-model="obj.listName"
            placeholder="ListName"
            class="w-100"
          />
        </vs-col>
      </vs-row>

      <div class="d-flex justify-content-end mt-3">
        <vs-button
          class="mr-2"
          @click="questionsDialog = false"
        >
          Cancelar
        </vs-button>
        <vs-button
          color="#66ff66"
          text-color="black"
          @click="saveQuestions()"
        >
          <span v-if="editingQuestions">Alterar</span>
          <span v-else>Salvar</span>
        </vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
/* Config */
import { baseUrl } from '@/global';

/* Services */
import axios from 'axios';
import Survey from '@/services/survey';

const ss = new Survey();

export default {
  name: 'GlobalQuestions',
  components: {},
  data: () => ({
    title: 'SurvTable',
    questions: [],
    obj: {},
    questionsDialog: false,
    editingQuestions: false,
  }),
  computed: {
    token() {
      const token = this.$store.state.accessToken.split('&');
      const res = token[0].replace('tk=', '');
      return res;
    }
  },
  methods: {
    getQuestions() {
      this.$store.dispatch('set_isLoadingActive', true);

      ss
        .getQuestions(this.token)
        .then(
          (res) => {
            this.questions = res.questions;
            this.$store.dispatch('set_isLoadingActive', false);
          },
          (err) => {
            this.$store.dispatch('set_isLoadingActive', false);
            alert(err);
          }
        );
    },

    editQuestions(questions) {
      this.questionsDialog = true;
      this.editingQuestions = true;
      this.obj = questions;
    },
    createNewQuestions() {
      this.editingQuestions = false;
      this.questionsDialog = true;
    },
    saveQuestions() {
      this.$store.dispatch('set_isLoadingActive', true);
      const url = `${baseUrl}/questions`;
      const object = this.obj;
      const headers = { headers: { Authorization: `Bearer ${this.token}` } };
      if (object.listType) object.listType = parseInt(object.listType);
      /* eslint-disable */
      axios
        .post(url, object, headers)
        .then(() => {
          this.$store.dispatch("set_isLoadingActive", false);
          let term = this.editingQuestions ? "atualizada" : "criada";
          this.$vs.notify({
            title: `Sucesso`,
            color: "primary",
            text: `Questão ${term} com sucesso`,
          });
        })
        .catch((err) => {
          this.$store.dispatch("set_isLoadingActive", false);
          alert(err);
        })
        .finally(() => {
          this.$store.dispatch("set_isLoadingActive", false);
          this.questionsDialog = false;
          this.editingQuestions = false;
          this.getQuestions();
        });
    },
    goToFanboxManagement(id) {
      this.$router.push({
        name: 'FanboxManagement',
        params: { selectedQuestions: id },
      });
    },
  },
  mounted() {
    this.getQuestions();
  },
};
</script>

<style>
</style>
